import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

import formStyles from './UserInfoForm.module.css';
import styles from './Auth.module.css';
import { fetchFromServer, debug } from '../helpers/Helper';
import { softLoginCheck, getUserInfo } from '../helpers/LoginCheck';
import { update } from 'jdenticon';

const SignupPage = () => {
    const [softLogin, setSoftLogin] = useState(false);
    const [userInfo, setUserInfo] = useState({});

    const [actionType, setType] = useState('Register');
    const [description, setDescription] = useState('Already have an account?');
    const [changeButton, setChangeButton] = useState('Sign in');
    const [visibility, setVisibility] = useState('auto');

    const [FN, setFN] = useState('');
    const [LN, setLN] = useState('');
    const [EML, setEML] = useState('');
    const [PWD, setPWD] = useState('');
    const [CNFMPWD, setCNFMPWD] = useState('');
    const [BETACODE, setBETACODE] = useState('');

    const cookies = new Cookies();
    const svgRef = useRef(null);
    const navigate = useNavigate();


    
    function checkCookieRedirect() {
        // const authToken = cookies.get('authorization');
        // debug("asking server for authToken ", authToken);
        //if (authToken) {
            const res = fetchFromServer('verifyCookie', 'GET', undefined, (res) => {
                debug(res);
                if (res.response === "success") {
                    debug('🟢 Already logged in, redirecting to covrai');
                    navigate("/covrai");
                } else {
                    cookies.remove('authorization');
                    debug('🔴 Invalid cookie token, token removed');
                }
            });

        //}
    }

    function storeCookie(token) {
        debug(`setting cooke with token: ${token}`);

        if (!token) {
            debug("no token");
            return;
        }

        cookies.set('authorization', token, {
            path: '/',
            maxAge: 2592000
        });

        debug(cookies.get('authorization'));
    }

    function removeCookie() {
        try {
            cookies.remove('authorization');
            window.location.reload();
        } catch (error) {
            debug("cookie not removed", error);
        };
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (actionType === "Register") {
            if (PWD !== CNFMPWD) {
                debug("password different");
            }

            let data = JSON.stringify({
                firstName: FN,
                lastName: LN,
                email: EML,
                password: PWD,
                code: BETACODE
            });

            if ( BETACODE === "" ) {
                alert("CovrAI is currently in Beta testing.\nPlease contact developers at info@covr-ai.com.");
                return;
            };

            if (FN === '' || LN === '' || EML === '' || PWD === '' || CNFMPWD === '' || BETACODE === '') {
                alert("Please fill all fields present.");
                return;
            };
            

            debug(data);
            const res = fetchFromServer('register', 'POST', data, (res) => {
                //storeCookie(res.token);
                if (res.response === "success") {
                    navigate("/sentverification");
                    //alert("Verification set, please check your email.");
                } else if (res.response === "Code not found or expired.") {
                    alert("Code Invalid.");
                } else {
                    alert("Registration failed, please try again, or contact us at inquiry@covr-ai.com");
                    console.log(res);
                };
                //checkCookieRedirect();
            });


        } else if (actionType === "Sign in") {

            let data = JSON.stringify({
                email: EML,
                password: PWD
            });

            debug(data);

            const res = await fetchFromServer('signin', 'POST', data, (res) => {
                // storeCookie(res.token);
                checkCookieRedirect();
            }, undefined, "🔴 server did not respond to signin request");
            
            if (res.status != 200) {
                alert(res.message);
            }
            debug(res);

        }

        debug("Form submitted, cookie might be set in async.");
    };

    const changeType = (event) => {
        event.preventDefault();
        debug(actionType);
        if (actionType === "Register") {
            setType('Sign in');
            setDescription('Invited for Beta testing?');
            setChangeButton('Register');
            setVisibility('none');
        } else if (actionType === "Sign in") {
            setType('Register');
            setDescription('Already have an account?');
            setChangeButton('Sign in');
            setVisibility('auto');
        }
        debug("changeTypeto ", actionType);
    }

    const GoogleSignin = (event) => {
        event.preventDefault();
        debug("Google Signin");
    }

    const FacebookSignin = (event) => {
        event.preventDefault();
        debug("Facebook Signin");
    }

    useEffect(() => {
        setSoftLogin(softLoginCheck());
        setUserInfo(getUserInfo());
    }, []);

    useEffect(() => {
        if (svgRef.current && getUserInfo().email) {
        // Generate the identicon with the user's email as a hash
        update(svgRef.current, getUserInfo().email);
        }
    }, [ userInfo ]);

    return (
        <React.StrictMode>
            <div className={styles['content-wrapper']}>
                <div className={formStyles['content-wrapper']}>
                    {!softLogin ?
                        <form>
                            <div className={`${formStyles['input-wrapper']} ${actionType === "Sign in" ? styles['invisible'] : ""}`}>
                                <label>
                                    <input
                                        type="text"
                                        name="First Name"
                                        placeholder="First Name"
                                        value={FN}
                                        onChange={(e) => setFN(e.target.value)}
                                    />
                                    First Name
                                </label>
                                <label>
                                    <input
                                        type="text"
                                        name="Last Name"
                                        placeholder="Last Name"
                                        value={LN}
                                        onChange={(e) => setLN(e.target.value)}
                                    />
                                    Last Name
                                </label>
                            </div>
                            <div className={formStyles['input-wrapper']}>
                                <label>
                                    <input
                                        type="text"
                                        name="Email"
                                        placeholder="Email"
                                        value={EML}
                                        onChange={(e) => setEML(e.target.value)}
                                    />
                                    Email*
                                </label>
                            </div>
                            <div className={formStyles['input-wrapper']}>
                                <label>
                                    <input
                                        type="password"
                                        name="Password"
                                        placeholder="Password"
                                        value={PWD}
                                        onChange={(e) => setPWD(e.target.value)}
                                    />
                                    Password*
                                </label>
                            </div>
                            <div className={`${formStyles['input-wrapper']} ${actionType === "Sign in" ? styles['invisible'] : ""}`}>
                                <label>
                                    <input
                                        type="password"
                                        name="Confirm Password"
                                        placeholder="Confirm Password"
                                        value={CNFMPWD}
                                        onChange={(e) => setCNFMPWD(e.target.value)}
                                    />
                                    Confirm Password*
                                </label>
                            </div>
                            <div className={`${formStyles['input-wrapper']} ${actionType === "Sign in" ? styles['invisible'] : ""}`}>
                                <label>
                                    <input
                                        type="password"
                                        name="Invite Code"
                                        placeholder="Beta Invite Code"
                                        value={BETACODE}
                                        onChange={(e) => setBETACODE(e.target.value)}
                                    />
                                    Invite Code*
                                </label>
                            </div>
                            <button className={styles['auth-button']} onClick={handleSubmit}><h2>{actionType}</h2></button>
                            <div className={styles['text-wrapper']}>
                                <span className={styles['switchTypeDescription']}>{description}</span> <a className={styles['switchTypeButton']} onClick={changeType}>{changeButton}!</a>
                            </div>

                            <div className={styles['dividor']}>
                                <span className={styles['dividor-text']}>or</span>
                            </div>
                            <button className={styles['auth-button']} onClick={GoogleSignin}><svg className={styles['form-logo']} focusable="false" aria-hidden="true" viewBox="0 0 16 16" width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.68 8.18182C15.68 7.61455 15.6291 7.06909 15.5345 6.54545H8V9.64364H12.3055C12.1164 10.64 11.5491 11.4836 10.6982 12.0509V14.0655H13.2945C14.8073 12.6691 15.68 10.6182 15.68 8.18182Z" fill="#4285F4"></path><path d="M8 16C10.16 16 11.9709 15.2873 13.2945 14.0655L10.6982 12.0509C9.98545 12.5309 9.07636 12.8218 8 12.8218C5.92 12.8218 4.15273 11.4182 3.52 9.52727H0.858182V11.5927C2.17455 14.2036 4.87273 16 8 16Z" fill="#34A853"></path><path d="M3.52 9.52C3.36 9.04 3.26545 8.53091 3.26545 8C3.26545 7.46909 3.36 6.96 3.52 6.48V4.41455H0.858182C0.312727 5.49091 0 6.70545 0 8C0 9.29455 0.312727 10.5091 0.858182 11.5855L2.93091 9.97091L3.52 9.52Z" fill="#FBBC05"></path><path d="M8 3.18545C9.17818 3.18545 10.2255 3.59273 11.0618 4.37818L13.3527 2.08727C11.9636 0.792727 10.16 0 8 0C4.87273 0 2.17455 1.79636 0.858182 4.41455L3.52 6.48C4.15273 4.58909 5.92 3.18545 8 3.18545Z" fill="#EA4335"></path></svg> <span className={styles['Button-Description']}>Signup with Google</span></button>
                            <button className={styles['auth-button']} onClick={FacebookSignin}><svg className={styles['form-logo']} focusable="false" aria-hidden="true" viewBox="0 0 16 16" width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.68 15.92C2.88 15.24 0 11.96 0 8C0 3.6 3.6 0 8 0C12.4 0 16 3.6 16 8C16 11.96 13.12 15.24 9.32 15.92L8.88 15.56H7.12L6.68 15.92Z" fill="url(#paint0_linear_795_116)"></path><path d="M11.12 10.2391L11.48 7.99914H9.36V6.43914C9.36 5.79914 9.6 5.31914 10.56 5.31914H11.6V3.27914C11.04 3.19914 10.4 3.11914 9.84 3.11914C8 3.11914 6.72 4.23914 6.72 6.23914V7.99914H4.72V10.2391H6.72V15.8791C7.16 15.9591 7.6 15.9991 8.04 15.9991C8.48 15.9991 8.92 15.9591 9.36 15.8791V10.2391H11.12Z" fill="white"></path><defs><linearGradient id="paint0_linear_795_116" x1="8" y1="0" x2="8" y2="15.9991" gradientUnits="userSpaceOnUse"><stop stop-color="#1AAFFF"></stop><stop offset="1" stop-color="#0163E0"></stop></linearGradient></defs></svg><span className={styles['Button-Description']}>Signup with Facebook</span></button>
                        </form>
                        :
                        <div className={styles['dash-wrapper']}>
                            <svg ref={ svgRef } width="10rem" height="10rem" data-jdenticon-value={ getUserInfo().email }></svg>
                            <div>
                                <h1>{userInfo.name}</h1>
                                <button onClick={ removeCookie }><h2>Logout</h2></button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </React.StrictMode>
    );
};

export default SignupPage;