// components/Header.js
import React from 'react';
import styles from './Footer.module.css';
import { Link } from 'react-router-dom';

function Banner() {
  return (
    <div className={styles['banner-wrapper']}>
      <div className={styles['content-wrapper']}>
        <div className={styles['option-wrapper']}>
          <div className={styles['column-wrapper']}>
            <h2>// CovrAI 😊</h2>
            <h3><Link to="">OneClick.ext</Link></h3>
            <h3><Link to="/About">The Team</Link></h3>
          </div>
          <div className={styles['column-wrapper']}>
            <h2>// Socials 🎉</h2>
            <h3><Link to ="https://www.linkedin.com/company/project-unmei" target="_blank" rel="noopener noreferrer">LinkedIn</Link></h3>
            <h3><Link to ="https://www.instagram.com/covrai_official" target="_blank" rel="noopener noreferrer">Instagram</Link></h3>
          </div>
          <div className={styles['column-wrapper']}>
            <h2>// Policies 👩‍⚖️</h2>
            <h3><Link to ="/tos">Terms of Service</Link></h3>
            <h3><Link to ="/privacy">Privacy Statement</Link></h3>
          </div>
        </div>
        <div className={styles['last-option-wrapper']}>
          <div className={styles['column-wrapper']}>
            <h2>// Support 💻</h2>
            <h3><Link to="https://discord.gg/wd9UvrGZBB">Discord</Link></h3>
            <h3><a href="mailto:info@covr-ai.com">info@covr-ai.com</a></h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
