// components/Header.js
import React from 'react';
import styles from './Banner.module.css';
import { Link } from 'react-router-dom';
import { softLoginCheck, getUserInfo } from '../helpers/LoginCheck';

import BannerSpacer from './BannerSpacer';

function Banner() {
  return (
    <React.StrictMode>
      <BannerSpacer />
      <div className={styles['banner-wrapper']}>
        <div className={styles['content-wrapper']}>
          <div className={styles['option-wrapper']}>
            <Link to="/"><img src={`${process.env.PUBLIC_URL}/logo.svg`} alt="Description of the SVG"></img></Link>
            <Link to="/about"><h2>About</h2></Link>
            <Link to="/pricing"><h2>Pricing</h2></Link>
            <Link to="/covrai"><h2>CovrAI</h2></Link>
          </div>
          <div className={styles['option-wrapper']}>
            <Link to="/auth"><h2>{ softLoginCheck() ? `Welcome, ${getUserInfo().name} 🧐` : "Register" }</h2></Link>
          </div>
        </div>
      </div>
      <div className={styles['beta-banner-wrapper']}>
        <h3>🚧 Beta Testing 🚧 Join our <grad><Link to="https://discord.gg/wd9UvrGZBB">Discord</Link></grad>!</h3>
      </div>
    </React.StrictMode>
  );
}

export default Banner;
