import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ProtectedRoute from '../helpers/ProctectedRoute';


const importAll = (r) => {
  const components = {};
  r.keys().forEach((key) => {
    // Extract the component name from the file path
    const componentName = key.replace('./', '').replace('.js', '');
    components[componentName] = r(key).default;
  });
  return components;
};

const pages = importAll(require.context('../pages', false, /\.js$/));

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<pages.Home />} />
        <Route path="/about" element={<pages.About />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/covrai" element={<pages.CovrAI />} />
        </Route>
        <Route path="/pricing" element={<pages.Pricing />} />
        <Route path="/auth" element={<pages.Auth />} />
        <Route path="/privacy" element={<pages.Privacy />} />
        <Route path="/tos" element={<pages.TermsOfService />} />
        <Route path="/loading" element={<pages.Loading />} />
        <Route path="/sentverification" element={<pages.SentVerificationEmail />} />
        <Route path="/verified" element={<pages.Verified />} />
        <Route path="*" element={<pages.NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
