// Home.js
import React, { useEffect } from 'react';


import Banner from '../components/Banner';
import Footer from '../components/Footer';
import styles from './Verified.module.css';

function Home() {
  // Redirect after 3 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = `/auth`;
    }, 3000);

    // Cleanup the timer if the component is unmounted before the timeout
    return () => clearTimeout(timer);
  }, []);
  return (
    <React.StrictMode>
      <Banner />
      <div className={styles["content-wrapper"]}>
        <h5>You have been successfully verified, you will be redirected to the login page in a few moments. 🙌🎉</h5>
      </div>
      <Footer />
    </React.StrictMode>
  );
}

export default Home;
