// Home.js
import React from 'react';


import Banner from '../components/Banner';
import Footer from '../components/Footer';
import styles from './SentVerificationEmail.module.css';

function Home() {
  return (
    <React.StrictMode>
      <Banner />
      <div className={styles["content-wrapper"]}>
        <h5>Verification email set, please check your inbox.😗</h5>
      </div>
      <Footer />
    </React.StrictMode>
  );
}

export default Home;
